const subCat = document.querySelectorAll(".submennu-category");
function updatePosition(){
    subCat.forEach(element => {
        var rect = element.getBoundingClientRect();
        var headerHeight = element.parentElement.offsetHeight;
        var isScroll = rect.top < element.offsetHeight;
        var isAtBottom = (rect.bottom >= window.innerHeight) && !isScroll;
   
        if (isScroll && element.offsetHeight > window.innerHeight - rect.top) {
            element.style.overflowY = 'scroll';
            element.style.maxHeight = window.innerHeight - rect.top +headerHeight + "px";
        }
        if (isAtBottom) {
            element.classList.remove("top");
            element.classList.add("bottom");
        } else {
            element.classList.remove("bottom");
            element.classList.add("top");
        }
    });
}
updatePosition();
window.addEventListener('resize', updatePosition);
