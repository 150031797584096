var contactForm = document.getElementById("contactForm");
var toastLive = document.getElementById("contactBtnToast");
if(contactForm) {

    contactForm.addEventListener("submit", function(e) {
        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var formData = {
            'data': 'lol',
        }
        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        const toast = new bootstrap.Toast(toastLive);
        toast.show();
        form.reset();
        xhr.send(formData);
    });
}
const toastFooter = document.getElementById("footerFormToast");
const footerForm = document.getElementById("footerForm");
if (footerForm){
    footerForm.addEventListener("submit", function (e) {
        e.preventDefault();
        var form = e.target;
        var actionUrl = form.getAttribute("action");
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        const toast = new bootstrap.Toast(toastFooter);
        toast.show();
        form.reset();
        xhr.send(formData);
    });
}