const lazyImages = document.querySelectorAll('.webpress-lazy-image');


const lazyImageObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
            entry.target.src = entry.target.dataset.src;
            observer.unobserve(entry.target);
        }
    });
}, { rootMargin: '400px' });


lazyImages.forEach(image => {
    lazyImageObserver.observe(image);
});
