require('./bootstrap');
require('./menu');
require('./toastr');
require('./swiper');
require('./introduce-page');
require('./product-detail');
require('./header');
require('./footer');
require('./contact');
require('./webpress_lazy_load');
