const toastLiveCart = document.getElementById("cartToast");
  const toastTrigger = document.getElementById("cartBuy");
  if (toastLiveCart && toastTrigger) {
    cartBuy.addEventListener("click", function () {
        var toast = new bootstrap.Toast(toastLiveCart);
        toast.show();
    });
}



