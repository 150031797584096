const swiper = new Swiper('.banner__swiper', {
    loop: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__banner',
      prevEl: '.swiper-button-prev__banner',
    },
    pagination: {
      el: ".banner__swiper-pagination",
      clickable: true,
  },
  });

  const flashSaleSwiper = new Swiper('.flash-sale-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__flash-sale',
      prevEl: '.swiper-button-prev__flash-sale',
    },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 4,
        }
      },
  });

  const categorySwiper = new Swiper('.category-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__category',
      prevEl: '.swiper-button-prev__category',
    },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 4,
        },
        1000: {
          slidesPerView: 6,
        }
      },
  });

  const productHotSwiper = new Swiper('.product-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__product-hot',
      prevEl: '.swiper-button-prev__product-hot',
    },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 4,
        }
      },
  });

  const productCategorySwiper = new Swiper('.flash-sale-category-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__product-category',
      prevEl: '.swiper-button-prev__product-category',
    },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 4,
        }
      },
  });

  const customerSwiper = new Swiper('.customer-swiper', {
    loop: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    pagination: {
      el: '.customer-swiper-pagination',
    },

    navigation: {
      nextEl: '.swiper-button-next__customer',
      prevEl: '.swiper-button-prev__customer',
    },
  });

  const newsSwiper = new Swiper('.news-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__news',
      prevEl: '.swiper-button-prev__news',
    },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 3,
        }
      },
  });

  const customerBrandSwiper = new Swiper('.customer-brand-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
      nextEl: '.swiper-button-next__customer-brand',
      prevEl: '.swiper-button-prev__customer-brand',
    },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 6,
        }
      },
  });

  const navCategorySwiper = new Swiper('.nav-category-swiper', {
    slidesPerView: 2.5
  });

  const productDetailSwiper = new Swiper('.product-detail-swiper', {
    navigation: {
      nextEl: '.swiper-button-next__product-detail',
      prevEl: '.swiper-button-prev__product-detail',
    },
    breakpoints: {
        320: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 4,
        }
      },
  });

  const productIntroSwiper = new Swiper('.product--spot-swiper', {
    // navigation: {
    //   nextEl: '.swiper-button-next__product-detail',
    //   prevEl: '.swiper-button-prev__product-detail',
    // },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 3,
        }
      },
  });

  const certificateSwiper = new Swiper('.certificate-swiper', {
    // navigation: {
    //   nextEl: '.swiper-button-next__product-detail',
    //   prevEl: '.swiper-button-prev__product-detail',
    // },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 2,
        }
      },
  });

  const teamIntroSwiper = new Swiper('.team-intro-swiper', {
    // navigation: {
    //   nextEl: '.swiper-button-next__product-detail',
    //   prevEl: '.swiper-button-prev__product-detail',
    // },
    breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 4,
        }
      },
  });