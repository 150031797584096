var headerHeight = document.querySelector("header").offsetHeight;
const header = document.querySelector("header");
const toggleClass = "sticky";

window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll > headerHeight) {
        header.classList.add(toggleClass);
    } else {
        header.classList.remove(toggleClass);
    }
});